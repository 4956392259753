import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { AccommodationUpdateAllError } from '~generated-types'
import { DateRange } from '@/modules/Accommodation'
import { ExpansionPanel } from '@/components/ExpansionPanel'
import { FetchStates } from '@/common/types'
import { H3 } from '@/components/Typography'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { GroupPanel } from './GroupPanel'
import { ReservationsOffsetContainer } from './ReservationsOffsetContainer'
import { SalesDetailsBase } from './SalesReservationList.types'
import { useReservationListContext } from './ReservationListState'

type Props = {
  defaultRange: DateRange | null | undefined
  hideTitle?: boolean
  salesId: string
  salesDetails: SalesDetailsBase
  readOnly?: boolean
}

export const ReservationList = ({
  defaultRange,
  hideTitle,
  salesId,
  salesDetails,
  readOnly,
}: Props) => {
  const theme = useTheme()

  const {
    addReservationGroup,
    handleMassUpdate,
    reservationGroups,
    saleType,
    state,
  } = useReservationListContext()

  const [isMovingProcess, setMovingProcess] = useState(false)
  const [idsToMove, setIdsToMove] = useState<string[]>([])
  const [issues, setIssues] = useState<AccommodationUpdateAllError['issues']>(
    []
  )

  const handleMoveReservations = (days: number) =>
    handleMassUpdate({
      offset: { days },
      targetIds: idsToMove,
    }).then((result) => setIssues(result?.issues || []))

  useEffect(() => {
    issues.length && setIssues([])
  }, [idsToMove])

  const renderLoading = () => (
    <Wrapper>
      <TitleWrapper justifyContent="space-between">
        <Title>
          <T>Accommodation:SalesReservationList.title</T>
        </Title>
      </TitleWrapper>
      <ReactLoading
        color={theme.palette.smoke.main}
        type={'spin'}
        height={24}
        width={24}
      />
    </Wrapper>
  )

  const renderError = () => (
    <Wrapper>
      <TitleWrapper justifyContent="space-between">
        <Title>
          <T>Accommodation:SalesReservationList.title</T>
        </Title>
      </TitleWrapper>
      <ExpansionPanel
        renderHeader={() => (
          <PlaceholderLabel>
            <T>Accommodation:SalesReservationList.error</T>
          </PlaceholderLabel>
        )}
        headerPadding={`${theme.spacing.gutter} 0 ${
          theme.spacing.gutter
        } ${theme.spacing.gu(3)}rem`}
      />
    </Wrapper>
  )

  const renderHeader = () => {
    if (hideTitle || (saleType === 'SALES' && !!reservationGroups.length)) {
      return (
        <FlexRow justifyContent="flex-end">
          {!isMovingProcess && (
            <InnocuousButton
              disabled={readOnly}
              onClick={() => setMovingProcess(true)}
            >
              <PrimaryColor>
                <FontAwesomeIcon
                  icon={['far', 'check-square']}
                  style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
                />
                <T>ResourceReservations:reservation.select</T>
              </PrimaryColor>
            </InnocuousButton>
          )}
        </FlexRow>
      )
    }

    return (
      <TitleWrapper justifyContent="space-between">
        <Title>
          {saleType === 'ENROLLMENT' ? (
            <T>Accommodation:SalesReservationList.sharedGroups</T>
          ) : (
            <T>Accommodation:SalesReservationList.title</T>
          )}
        </Title>
        <FlexRow>
          {!isMovingProcess && (
            <InnocuousButton
              disabled={readOnly}
              onClick={() => setMovingProcess(true)}
            >
              <PrimaryColor>
                <FontAwesomeIcon
                  icon={['far', 'check-square']}
                  style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
                />
                <T>ResourceReservations:reservation.select</T>
              </PrimaryColor>
            </InnocuousButton>
          )}
          {(saleType === 'EVENT' || !reservationGroups.length) && (
            <InnocuousButton
              disabled={readOnly}
              compact
              onClick={() => addReservationGroup('')}
            >
              <PrimaryColor>
                <T>Accommodation:SalesReservationList.addGroup</T>
              </PrimaryColor>
            </InnocuousButton>
          )}
        </FlexRow>
      </TitleWrapper>
    )
  }

  return state === FetchStates.LOADING ? (
    renderLoading()
  ) : state === FetchStates.IDLE || state === FetchStates.EMPTY ? (
    <Wrapper>
      {isMovingProcess && (
        <ReservationsOffsetContainer
          idsToMove={idsToMove}
          readOnly={readOnly}
          issues={issues}
          onCancel={() => {
            setMovingProcess(false)
            setIssues([])
          }}
          handleMoveReservations={handleMoveReservations}
          setIdsToMove={setIdsToMove}
        />
      )}

      {renderHeader()}

      <FlexColumn>
        {reservationGroups.length ? (
          reservationGroups.map((group) => (
            <GroupPanel
              key={`group-${group.id}`}
              salesId={salesId}
              group={group}
              readOnly={readOnly}
              salesDetails={salesDetails}
              defaultRange={defaultRange}
              selectProcess={isMovingProcess}
              targetIdsToMove={idsToMove}
              setTargetIdsToMove={setIdsToMove}
            />
          ))
        ) : (
          <ExpansionPanel
            renderHeader={() => (
              <PlaceholderLabel>
                <T>Accommodation:SalesReservationList.noGroups</T>
              </PlaceholderLabel>
            )}
            headerPadding={`${theme.spacing.gutter} 0 ${
              theme.spacing.gutter
            } ${theme.spacing.gu(3)}rem`}
          />
        )}
      </FlexColumn>
    </Wrapper>
  ) : (
    renderError()
  )
}

const PlaceholderLabel = styled.span`
  text-align: center;
  width: 100%;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const Title = styled(H3)`
  font-variant: all-small-caps;
  font-weight: 500;
  margin: 0;

  ${({ theme }) => css`
    line-height: ${theme.spacing.gutterBig};
  `}
`

const TitleWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutter};
  `}
`

import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { SalesEventSettingsInput } from '~generated-types'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useUpdateEventSettings } from '@/modules/Sales/mutations'

import {
  EnrollmentInterval,
  WebshopLink,
  WebshopVisibility,
} from './components'
import { ContentSection } from '../../../components'
import { WebshopSettings as WebshopSettingsType } from '../../../types'

type Props = {
  eventSettings: NonNullable<NonNullable<WebshopSettingsType>['eventSettings']>
}

export const EventSettings = ({
  eventSettings: { enrollEnd, enrollStart, visibility, link },
}: Props) => {
  const {
    data: { id },
    refresh,
  } = useSalesDetailsContext()

  const [updateEventSettings] = useUpdateEventSettings()

  const handleSetEventSettings = (input: Partial<SalesEventSettingsInput>) =>
    updateEventSettings({
      variables: {
        input: {
          eventSettings: { enrollEnd, enrollStart, id, visibility, ...input },
        },
      },
    })
      .then(
        ({ data }) =>
          data?.salesSetWebshopSettings.webshopSettings?.eventSettings
      )
      .catch(() => undefined)

  return (
    <ContentSection title={<T>SalesDetails:eventSettings.title</T>}>
      <Wrapper>
        <WebshopVisibility
          setEventSettings={handleSetEventSettings}
          visibility={visibility}
        />

        <VerticalDivider />

        <EnrollmentInterval
          refresh={refresh}
          enrollEnd={enrollEnd}
          enrollStart={enrollStart}
          setEventSettings={handleSetEventSettings}
        />

        <VerticalDivider />

        <WebshopLink link={link} />
      </Wrapper>
    </ContentSection>
  )
}

////////////

const VerticalDivider = styled.div`
  height: 100%;
  width: 1px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
  `}
`

const Wrapper = styled(FlexRow)`
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};
    margin-right: ${theme.spacing.gu(2)}rem;
    padding: ${theme.spacing.gu(2)}rem;
    padding-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

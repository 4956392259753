import { CSSProperties, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { OtherPaymentType, OtherPaymentTypesQuery } from '@/modules/Order/types'
import { Palette, useTheme } from '@/theme'
import { FlexColumn } from '@/components/Layout'
import { InlineModalSection } from '@/components/InlineModal'
import { orderQueries } from '@/modules/Order'
import { T } from '@/modules/Language'

import { Title } from './common'

type Props = {
  typeId: string
  setTypeId: (typeId: string) => void
}

export const PaymentTypeSelector = ({ typeId, setTypeId }: Props) => {
  const [typeOptions, setTypeOptions] = useState<Option[] | []>([])
  const { palette } = useTheme()

  const { data, loading } = useQuery<OtherPaymentTypesQuery>(
    orderQueries.OTHER_PAYMENT_TYPES
  )

  useEffect(() => {
    if (data) {
      const options = data.registry.otherPaymentTypes.map(
        (type: OtherPaymentType) => ({
          label: type.name,
          value: type.id,
        })
      )

      setTypeOptions(options)
    }
  }, [data])

  return (
    <InlineModalSection style={{ flex: 3, marginTop: 0 }}>
      <FlexColumn flex={1} noPadding>
        <Title>
          <T>Orders:Payments.field.type</T> *
        </Title>

        <ThemedSelect
          extraStyles={getExtraStyles(palette)}
          isLoading={loading}
          menuPortalTarget={document.body}
          name="other-payment-type-selector"
          noOptionsMessage={() => (
            <T>Orders:Payments.modal.emptyOtherPaymentTypes</T>
          )}
          onChange={(option?: Option | null) => setTypeId(option?.value || '')}
          options={typeOptions}
          placeholder=""
          value={
            typeId ? typeOptions.find((o: Option) => o.value === typeId) : null
          }
        />
      </FlexColumn>
    </InlineModalSection>
  )
}

////////

const getExtraStyles = (palette: Palette) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    width: '100%',
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    '&:hover': {
      background: palette.smoke.lighter,
    },
    borderRadius: '6px',
    cursor: 'pointer',
    height: '35px',
    minHeight: '35px',
  }),
  dropdownIndicator: (styles: CSSProperties) => ({
    ...styles,
    padding: '6px',
  }),
  singleValue: (styles: CSSProperties) => ({
    ...styles,
    color: palette.text.light,
  }),
  valueContainer: (styles: CSSProperties) => ({
    ...styles,
    padding: '2px 6px',
  }),
})

import { Restaurant } from '~generated-types'
import { useState } from 'react'

export type AllergyBy = 'MEAL' | 'SALE'
export type EnrollmentDisplay = 'GROUPED' | 'SEPARATE'
export type ListType = 'ALL' | 'ALLERGY' | 'MEAL' | 'PLANNING'
export type RestaurantKey = Restaurant['id']

export default function usePacketMealListControls() {
  const [allergyBy, setAllergyBy] = useState<AllergyBy>('MEAL')
  const [enrollmentDisplay, setEnrollmentDisplay] =
    useState<EnrollmentDisplay>('GROUPED')
  const [listType, setListType] = useState<ListType>('ALL')
  const [restaurantKey, setRestaurantKey] = useState<RestaurantKey[]>([])

  return {
    allergyBy,
    enrollmentDisplay,
    listType,
    restaurantKey,
    setAllergyBy,
    setEnrollmentDisplay,
    setListType,
    setRestaurantKey,
  }
}

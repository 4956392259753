import { gql, useQuery } from '@apollo/client'

import {
  FeatureStatus,
  NavExternalView,
  NavInternalView,
  WillbaConfigQuery as QueryData,
  WillbaConfigQueryVariables as QueryVariables,
} from '~generated-types'
import { NavDropdownItemType, NavItem } from '@/components/MainNavbar'

import { globalRoutes } from './globalRoutes'

const QUERY = gql`
  query WillbaConfig {
    registry {
      config {
        catalogInternalPriceEditing
        marginalTax
        navbar {
          items {
            items {
              ... on NavExternalView {
                isEmbedded
                name
                openInNewTab
                slug
                url
              }
              ... on NavInternalView {
                openInNewTab
                type
              }
            }
            sort
            type
          }
        }
        salesCommission
        salesInternalPriceEditing
        timezone {
          name
        }
      }
    }
  }
`

export const useWillbaConfig = () => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  const isCatalogInternalPriceEnabled =
    !!data &&
    data.registry.config.catalogInternalPriceEditing === FeatureStatus.Enabled

  const isCommissionEnabled =
    !!data && data.registry.config.salesCommission === FeatureStatus.Enabled

  const isMarginalTaxEnabled =
    !!data && data.registry.config.marginalTax === FeatureStatus.Enabled

  const isSalesInternalPriceEnabled =
    !!data &&
    data.registry.config.salesInternalPriceEditing === FeatureStatus.Enabled

  const navItems: NavItem[] | null = data
    ? data.registry.config.navbar.items.map(({ items, sort, type }) => ({
        items: items.map((item) => {
          const externalItem = item as NavExternalView
          const internalItem = item as NavInternalView

          return item.__typename === 'NavInternalView'
            ? {
                key: internalItem.type,
                openInNewTab: internalItem.openInNewTab,
                to: globalRoutes[internalItem.type],
                type: NavDropdownItemType.InternalItem,
              }
            : {
                isEmbedded: externalItem.isEmbedded,
                name: externalItem.name,
                openInNewTab: externalItem.openInNewTab,
                to: `${globalRoutes[type]}/${externalItem.slug}`,
                type: NavDropdownItemType.ExternalItem,
                url: externalItem.url,
              }
        }),
        key: type,
        sort,
      }))
    : null

  const timezone: string | null = data
    ? data.registry.config.timezone.name
    : null

  return {
    error,
    isCatalogInternalPriceEnabled,
    isCommissionEnabled,
    isMarginalTaxEnabled,
    isSalesInternalPriceEnabled,
    loading,
    navItems,
    timezone,
  }
}

import { gql, useQuery } from '@apollo/client'
import { Moment } from 'moment'

import type {
  UseDeparturesForDateQuery as QueryData,
  UseDeparturesForDateQueryVariables as QueryVariables,
  UseDeparturesForDateCustomerFragment,
  UseDeparturesForDateParticipantFragment,
  UseDeparturesForDateRoomReservationFragment,
} from '~generated-types'
import { generateCompareFn } from '@/utils/arrays'
import { OPEN_STATES } from '@/modules/Sales'
import { SalesType } from '~generated-types'

export type DeparturesCustomer = UseDeparturesForDateCustomerFragment
export type DeparturesParticipant = UseDeparturesForDateParticipantFragment
export type DeparturesRoomReservation =
  UseDeparturesForDateRoomReservationFragment
export type DeparturesSalesFacet =
  QueryData['departingSalesForDate'][0]['facet']
export type DeparturesSalesPaymentAgreement =
  QueryData['departingSalesForDate'][0]['paymentAgreement']

const QUERY = gql`
  fragment UseDeparturesForDateCustomer on Customer {
    customerNumber
    id

    ... on CustomerOrganization {
      organization {
        businessId
        name
      }
    }

    ... on CustomerPerson {
      person {
        email
        firstName
        lastName
        phone
      }
    }
  }

  fragment UseDeparturesForDateRoomReservation on RoomReservation {
    id
    request {
      beds
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
      extraBeds
      features {
        icon
        id
        name
        shortName
      }
      info
      room {
        beds
        building {
          id
          name
        }
        extraBeds
        id
        number
      }
    }
    target {
      id
      name
    }
  }

  fragment UseDeparturesForDateBedService on ServiceParticipantBed {
    accommodationTarget {
      id
      name
    }
    dates {
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
    }
    participantRoom {
      id
      roomReservation {
        id
      }
    }
    purchaseProduct {
      id
      product {
        id
        name
      }
      totalPrice {
        amount
        amountVatIncluded
        vatAmount
      }
    }
  }

  fragment UseDeparturesForDateParticipant on SalesParticipant {
    ageCategory {
      key
      shortName
    }
    additionalInfo
    firstName
    id
    lastName
    sales {
      id
      orderNumber
      paymentAgreement {
        id
        name
        code
      }
    }
    services {
      id
      ... on ServiceParticipantBed {
        ...UseDeparturesForDateBedService
      }
    }
  }

  query UseDeparturesForDate(
    $input: SalesForDateInput!
    $enrollmentStates: [SalesState!]
  ) {
    departingSalesForDate(input: $input) {
      accommodation {
        id
        roomReservations(includeRoomTypeRooms: true) {
          ...UseDeparturesForDateRoomReservation
        }
      }
      customer {
        customer {
          ...UseDeparturesForDateCustomer
        }
      }
      facet {
        id
        name
        abbreviation
        color
        dimensionTarget
        features {
          feature
          defaultVisibility
        }
      }
      id
      lifecycle {
        state
      }
      name
      orderNumber
      participantConnection(
        input: {
          filter: { enrollmentStates: $enrollmentStates }
          pagination: { size: 10000 }
        }
      ) {
        nodes {
          ...UseDeparturesForDateParticipant
        }
      }
      paymentAgreement {
        id
        name
        code
      }
      type
    }
  }
`

interface UseDeparturesForDateProps {
  date: Moment
}

export interface DeparturesSales {
  customer: DeparturesCustomer | null
  facet: DeparturesSalesFacet
  id: string
  name: string | null
  orderNumber: number | null
  participants: DeparturesParticipant[]
  paymentAgreement: DeparturesSalesPaymentAgreement | null
  roomsById: Record<string, DeparturesRoomReservation>
  type: SalesType.Sales | SalesType.Event
}

interface UseDeparturesForDateHook {
  error: boolean
  loading: boolean
  sales: Readonly<DeparturesSales[]>
}

export const useDeparturesForDate = ({
  date,
}: UseDeparturesForDateProps): UseDeparturesForDateHook => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      enrollmentStates: OPEN_STATES,
      input: {
        date: date.format('YYYY-MM-DD'),
      },
    },
  })

  const parsedSales: DeparturesSales[] = []

  data?.departingSalesForDate
    .filter(({ lifecycle }) => OPEN_STATES.includes(lifecycle.state))
    .forEach(
      ({
        accommodation,
        facet,
        id,
        name,
        orderNumber,
        paymentAgreement,
        type,
        ...rest
      }) => {
        const roomsById = accommodation.roomReservations.reduce(
          (acc: Record<string, DeparturesRoomReservation>, val) => ({
            ...acc,
            [val.id]: val,
          }),
          {}
        )

        if (type === SalesType.Event || type === SalesType.Sales) {
          parsedSales.push({
            customer: rest.customer?.customer || null,
            facet,
            id,
            name,
            orderNumber,
            participants: rest.participantConnection.nodes,
            paymentAgreement,
            roomsById,
            type,
          })
        } else {
          console.warn(`Unsupported type [${type}]`)
        }
      }
    )

  const salesComparator = generateCompareFn(['name', 'id'])

  return {
    error: !!error,
    loading,
    sales: parsedSales.sort((a, b) => {
      const customerOrder = getCustomerName(a.customer).localeCompare(
        getCustomerName(b.customer)
      )

      return customerOrder === 0 ? salesComparator(a, b) : customerOrder
    }),
  }
}

export default useDeparturesForDate

////////////

const getCustomerName = (customer: DeparturesCustomer | null): string => {
  if (!customer) {
    return ''
  }

  if (customer.__typename === 'CustomerOrganization') {
    return customer.organization.name || ''
  } else {
    return `${customer.person.lastName}${customer.person.firstName}`
  }
}

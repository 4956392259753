import styled, { useTheme } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import { ReactNode } from 'react'

import { Button } from '../Button'
import { FlexRow } from '../Layout'

type Props = {
  onClick: () => void
  infoMessage: ReactNode
  indicationMessage?: ReactNode
  actionLabel?: ReactNode
  saleReadOnly?: boolean
  loading?: boolean
  padding?: boolean
}

export const WarningBar = ({
  onClick,
  infoMessage,
  indicationMessage,
  actionLabel,
  saleReadOnly,
  loading,
  padding,
}: Props) => {
  const theme = useTheme()
  return (
    <Wrapper padding={padding}>
      <ContentWrapper padding={padding}>
        <Message>
          <WarningIcon
            fixedWidth
            icon="triangle-exclamation"
            size="2xl"
            color={theme.palette.gold}
          />
          <Text>{infoMessage}</Text>
        </Message>
        <Actions>
          {indicationMessage && <Text>{indicationMessage}</Text>}
          {actionLabel && (
            <Button disabled={saleReadOnly} onClick={onClick} color="primary">
              {loading && (
                <Loading>
                  <ReactLoading type={'spin'} height={12} width={12} />
                </Loading>
              )}
              {actionLabel}
            </Button>
          )}
        </Actions>
      </ContentWrapper>
    </Wrapper>
  )
}

/////////

const Wrapper = styled(FlexRow)<{ padding?: boolean }>`
  background: ${({ theme }) => theme.palette.warning.lighter};

  padding: ${({ padding, theme }) =>
    padding
      ? 0
      : `${theme.spacing.gu(2)}rem ${theme.spacing.gu(4)}rem ${theme.spacing.gu(
          2
        )}rem ${theme.spacing.gu(2)}rem`};
`
const ContentWrapper = styled(FlexRow)<{ padding?: boolean }>`
  width: 100%;
  margin: 0 auto;
  max-width: 1500px;
  min-width: 1100px;
  justify-content: space-between;

  padding: ${({ padding, theme }) =>
    padding
      ? `${theme.spacing.gu(2)}rem ${theme.spacing.gu(3)}rem ${theme.spacing.gu(
          2
        )}rem ${theme.spacing.gu(1)}rem`
      : 0};
`
const Message = styled(FlexRow)`
  align-items: center;
`
const WarningIcon = styled(FontAwesomeIcon)`
  padding-left: ${({ theme }) => `${theme.spacing.gu(0.8)}rem `};
`
const Actions = styled(FlexRow)`
  align-items: center;
`
const Text = styled.span`
  font-weight: 500;
  margin: ${({ theme }) => `0 ${theme.spacing.gu(2)}rem `};
`
const Loading = styled.span`
  margin-right: ${({ theme }) => `${theme.spacing.gu(1)}rem`};
`

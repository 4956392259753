import moment, { Moment } from 'moment'
import { Ref, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import {
  SalesProductSettings,
  SalesProductUpdateInput,
  UpdatePublishedProductMutation,
} from '~generated-types'

import { InlineModal, InlineModalFooter } from '@/components/InlineModal'
import { DateRangePicker } from '@/components/TimeControls'
import { InnocuousButton } from '@/components/ExtraButtons'
import { ModalContainer } from '@/components/Modal'
import { SalesProduct } from '@/modules/Products/types'
import { T } from '@/modules/Language'

type Range = { from: Moment; to: Moment }

interface Props {
  id: string
  readOnly: boolean
  purchaseDates: SalesProductSettings['purchaseDates']
  updateProduct: (
    input: SalesProductUpdateInput
  ) => Promise<SalesProduct | UpdatePublishedProductMutation | null | undefined>
  noMargin?: boolean
}

export const DatesSelector = ({
  id,
  readOnly,
  purchaseDates,
  updateProduct,
  noMargin,
}: Props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [range, setRange] = useState<Range | null>(null)

  const handleSetDates = (newRange: Range | null) =>
    updateProduct({
      id,
      settings: {
        purchaseDates: newRange
          ? {
              end: newRange.to.format('YYYY-MM-DD'),
              start: newRange.from.format('YYYY-MM-DD'),
            }
          : null,
      },
    })

  const handleCloseModal = (newRange: Range | null) => {
    handleSetDates(newRange)
    setModalOpen(false)
  }

  useEffect(() => {
    setRange(
      purchaseDates
        ? { from: moment(purchaseDates.start), to: moment(purchaseDates.end) }
        : null
    )
  }, [purchaseDates])

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <DateRangePicker
            setValue={(range) => range && setRange(range)}
            value={range}
          />

          {purchaseDates && (
            <InlineModalFooter justifyContent="center">
              <InnocuousButton
                compact
                noNudge
                onClick={() => {
                  setRange(null)
                  handleCloseModal(null)
                }}
              >
                <T>Products:Settings.dates.clear</T>
              </InnocuousButton>
            </InlineModalFooter>
          )}
        </InlineModal>
      }
      onClose={() => handleCloseModal(range)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Button
          noMargin={noMargin}
          disabled={readOnly}
          onClick={() => setModalOpen(true)}
          ref={ref as Ref<HTMLButtonElement> | undefined}
        >
          {purchaseDates ? (
            formatDate(purchaseDates.start, purchaseDates.end)
          ) : (
            <Placeholder>
              <T>Products:Settings.dates.empty</T>
            </Placeholder>
          )}
        </Button>
      )}
      zIndex={10006}
    />
  )
}

/////

const formatDate = (start: string, end: string) => {
  const isSameYear = moment(start).isSame(moment(end), 'year')

  const startDate = moment(start).format(
    isSameYear ? 'dd, DD.MM' : 'dd, DD.MM.YY'
  )
  const endDate = moment(end).format('dd, DD.MM.YY')

  return `${startDate} – ${endDate}`
}

const Button = styled.button<{ noMargin?: boolean }>`
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border-radius: 4px;

  ${({ theme, noMargin }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.dark};
    font-size: ${theme.typography.fontSizeSmall};
    color: ${theme.palette.text.light};
    height: ${theme.spacing.gu(4)}rem;
    min-width: ${theme.spacing.gu(25)}rem;
    ${noMargin ? '' : `margin-right: ${theme.spacing.gu(2)}rem;`}
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}

  &:disabled {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
      color: ${theme.palette.text.light};
      cursor: not-allowed;
    `}
  }

  &:hover:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &:active:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.smoke.extraDark};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { Chip } from '@/components/Chip'

import { RoomLabel } from '~generated-types'

interface Props {
  feature: RoomLabel
}

export const FeatureChip = ({ feature: { icon, shortName } }: Props) => (
  <Chip color="primary" size="xs" variant="outlined">
    {icon ? (
      <FontAwesomeIcon icon={icon as IconProp} />
    ) : (
      <span>{shortName}</span>
    )}
  </Chip>
)

export default FeatureChip
